import { get, put, Delete, post } from "../axios";

// 课程管理-课次管理列表
export const courseClassNumPage = (params) =>
  get(`/web/course/course/classNumPage`, params);

// 课次新增
export const courseAddClassNumber = (params) =>
  post(`/web/course/course/addClassNumber`, params);

// 课次编辑
export const courseUpdateClassNumber = (params) =>
  post(`/web/course/course/updateClassNumber`, params);

// 课次查看
export const courseClassNumberView = (params) =>
  get(`/web/course/course/classNumberView/` + params, "");

// 课次删除
export const courseDeleteClassNumber = (params) =>
  Delete(`/web/course/course/deleteClassNumber/` + params, "");
